import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home'),
    meta: {
      title: '泰克尼康科技有限公司-官方网站 Technican Technology Co.,Ltd',
    }
  },
  {
    path: '/freeze',
    name: 'freeze',
    component: () => import('../views/Freeze'),
    meta: {
      title: '关于冻眠',
    }
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('../views/Join'),
    meta: {
      title: '合作加盟',
    }
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/Case'),
    meta: {
      title: '应用案例',
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About'),
    meta: {
      title: '关于我们',
    }
  },
  {
    path: '/freezeconcept',
    name: 'freezeconcept',
    component: () => import('../views/FreezeConcept'),
    meta: {
      title: '冻眠科普',
    }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/News'),
    meta: {
      title: '新闻咨询',
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/Detail'),
    meta: {
      title: '内容详情',
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})


router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  next()
})

export default router
