<template>
  <div class="footers">
    <div class="introduce">
      <div class="constitute">
        <el-card class="box-card" shadow="never">
          <div class="footers-title">集团架构</div>
          <div v-for="item in list1" :key="item" class="text">
            {{item }}
          </div>
        </el-card>
        <el-card class="box-card" shadow="never">
          <div v-for="item in list2" :key="item" class="text">
            {{item }}
          </div>
        </el-card>
      </div>
      <div class="qrcode">
        <!-- <div class="phone-app">
          <img :src="require('@/assets/qr.png')" width="142" height="142">
          <p>手机APP</p>
        </div> -->
        <div class="wechat">
          <img :src="require('@/assets/qrcode1.png')" width="142" height="142">
          <p>微信公众号</p>
        </div>
        <div class="wechat-applet">
          <img :src="require('@/assets/qrcode2.png')" width="142" height="142">
          <p>微信小程序</p>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="copyright-p">
        <span>泰克尼康科技有限公司 ©版权所有 2022</span>
        <span><a href="//beian.miit.gov.cn">备案号：粤ICP备2020101471号</a></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footers',
  data () {
    return {
      list1: ['广州泰克尼康冻眠技术有限公司', '泰克尼康（广州）食品科技有限公司', '泰克尼康文化传播（广州）有限公司', '泰克尼康教育科技（广州）有限公司', '泰克尼康冻眠科学研究院（广州）有限公司'],
      list2: ['泰克尼康冻眠市场管理（广州）有限公司', '泰克尼康冻眠技术装备（广东）有限公司', '泰克尼康科技（重庆）有限责任公司', '泰克尼康食品科技（海南）有限公司'],
    }
  }
}
</script>

<style lang="less" scoped>
.footers {
  width: 100%;
  min-width: 1600px;
  .introduce {
    display: flex;
    justify-content: space-around;
    width: 100%;
    min-width: 1600px;
    padding: 80px 250px 50px;
    background-color: rgba(10, 12, 13, 0.03);
    .constitute {
      position: relative;
      // flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .box-card {
        position: relative;
        min-width: 350px;
        border: 0;
        background: transparent;
        overflow: visible;
        .text {
          font-size: 16px;
          line-height: 21px;
          padding: 10px 0;
          text-align: left;
        }
        .footers-title {
          position: absolute;
          top: -25px;
          left: 0;
          color: #00a0e9;
          font-size: 26px;
          line-height: 35px;
        }
      }
    }
    .qrcode {
      position: relative;
      // flex: 1;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 40px;
      // width: 480px;
      .wechat-applet,
      .wechat,
      .phone-app {
        padding: 40px;
      }
      p {
        font-size: 26px;
        line-height: 35px;
        color: #00a0e9;
        text-align: center;
        padding: 12px 0;
      }
    }
  }
  .copyright {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 82px;
    background-color: #00a0e9;
  }
  .copyright-p {
    color: #fff;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    span {
      margin-right: 20px;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
  }
}
</style>