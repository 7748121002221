import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import moment from 'moment'
import * as custom from '@/utils/filters'
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";

Object.keys(custom).forEach(key => { Vue.filter(key, custom[key]) })
Vue.prototype.$AOS = AOS
Vue.prototype.$axios = axios
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.realmURL = 'https://freeze.ganmiaoedu.com'
Vue.prototype.$moment = moment

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
