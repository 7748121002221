<template>
  <div id="app">
    <router-view />
    <Partner v-if="$route.name!='detail'&&$route.name!='news'" />
    <Contact v-if="$route.name!='detail'&&$route.name!='news'" />
    <Footer />
  </div>
</template>

<script>
import Partner from '@/components/partner'
import Contact from '@/components/contact'
import Footer from '@/components/footer'
export default {
  data () {
    return {

    }
  },
  created () {
    window.scrollTo(0, 0);
  },
  mounted () {
    this.$AOS.init();
    // AOS.init({   // 设置全局配置
    //   offset: 200,
    //   duration: 600,   //持续时间
    //   easing: 'ease-in-sine',
    //   delay: 100
    // })
  },
  methods: {
    // handleScroll () {
    //   // 兼容
    //   var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    //   if (scrollTop >= 6000) {
    //     console.log(scrollTop)
    //   }
    // },
  },
  components: {
    Partner,
    Contact,
    Footer,
  }
}
</script>
<style lang="less">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1600px;
  height: 100%;
}
.el-menu--popup-bottom-start {
  background-color: #fff !important;
}
</style>
