<template>
  <div class="contact">
    <div class="contact-us-tit">
      <span class="span1">联系我们 / </span>
      <span class="span2">contact us</span>
      <hr class="contact-hr">
    </div>
    <div class="contact-us-info">
      <div class="address mgr">
        <img :src="require('@/assets/about/address.png')">
        <span>ADDRESS</span>
        <span class="eef">{{list.address}}</span>
      </div>
      <div class="tel mgr">
        <img :src="require('@/assets/about/phone.png')">
        <span>PHONE</span>
        <span class="eef">{{list.phone}}</span>
      </div>
      <div class="email">
        <img :src="require('@/assets/about/email.png')">
        <span>E-MAIL</span>
        <span class="eef">{{list.email}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: { address: '广东省广州市南沙区北龙路80号102室', phone: '17302109490', email: 'tcnctomin@163.com' }
    }
  },
}
</script>

<style lang="less" scoped>
.contact {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 1400px;
  height: 300px;
  background-color: transparent;
  margin: 100px auto;
  padding: 60px 80px;
  background-color: #f8f8f8;
}

.contact .contact-us-tit .span1 {
  font-size: 26px;
  color: #00a0e9;
}

.contact .contact-us-tit .span2 {
  font-size: 28px;
  color: #000;
  text-transform: uppercase;
}

.contact .contact-hr {
  width: 70px;
  height: 3px;
  background-color: #00a0e9;
  border: 0;
  margin-top: 15px;
}

.contact .contact-us-info {
  position: relative;
  display: flex;
  font-size: 20px;
}

.contact .contact-us-info .address,
.contact .contact-us-info .tel,
.contact .contact-us-info .email {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  margin-left: 45px;
}

.contact .contact-us-info .address img,
.contact .contact-us-info .tel img,
.contact .contact-us-info .email img {
  position: absolute;
  top: 2px;
  left: -25px;
  width: 50px;
  height: 50px;
}

.contact .contact-us-info .eef {
  margin-top: 5px;
  color: rgba(51, 44, 43, 0.6);
}

.contact .contact-us-info .mgr {
  border-right: 2px solid rgba(51, 44, 43, 0.6);
}
</style>