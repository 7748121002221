<template>
  <div class="partner">
    <div class="en-title">Cooperative Partner</div>
    <div class="chs-title">合作伙伴</div>
    <div class="partner-all">
      <div class="partner-item" v-for="(item,i) in list" :key="i">
        <img :src="item.url" :width="item.w" :height="item.h">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [
        { url: require('@/assets/partner/partner1.png'), w: '250', h: '87' },
        { url: require('@/assets/partner/partner2.png'), w: '199', h: '104' },
        { url: require('@/assets/partner/partner3.png'), w: '233', h: '85' },
        { url: require('@/assets/partner/partner4.png'), w: '194', h: '136' },
        { url: require('@/assets/partner/partner5.png'), w: '179', h: '113' },
        { url: require('@/assets/partner/partner6.png'), w: '199', h: '201' },
        { url: require('@/assets/partner/partner7.png'), w: '221', h: '138' },
        { url: require('@/assets/partner/partner8.png'), w: '144', h: '143' },
        { url: require('@/assets/partner/partner9.png'), w: '217', h: '92' },
        { url: require('@/assets/partner/partner10.png'), w: '129', h: '42' },
        { url: require('@/assets/partner/partner11.png'), w: '187', h: '171' },
      ]
    }
  },
}
</script>

<style lang="less" scoped>
.partner {
  position: relative;
  width: 1400px;
  margin: 50px auto;
  .en-title {
    font-size: 48px;
    font-weight: bold;
    color: #e6e6e6;
    letter-spacing: 10px;
    text-align: center;
  }
  .chs-title {
    font-size: 36px;
    font-weight: bold;
    color: #000;
    letter-spacing: 5px;
    text-align: center;
    margin-top: -30px;
  }
  .partner-all {
    display: flex;
    flex-wrap: wrap;
    width: 1450px;
    margin: 50px 0 50px -50px;
    .partner-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .partner-item img {
      margin-left: 50px;
    }
  }
}
</style>